import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { newModalStyle } from "../../reusable/styles";
import LabelWithHR from "../../common/labels/labelWithHR";
import Style from "./modalEditTicket.module.css";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import Button from "../../common/button/button";

import {cancellationReasonsParking, cancellationReasonsDriveOff} from "../../globals.js";

registerLocale("de", de);

const ModalCancelTicket = (props) => {
  const { handleSubmit, control } = useForm();
  const [cancelTicketReq, setCancelTicketReq] = useState({
    ticketId: props.ticketForEdit?.id,
    cancellationReason: "",
    justification: ""
  });
const cancellationReasons = props.ticketForEdit?.gasStationZone? cancellationReasonsDriveOff : cancellationReasonsParking;

  const ticketCancel = () => {
    props.cancelTicket(cancelTicketReq);
    props.close();
  };

  const { t } = useTranslation();
  return (
    <Modal
      open={props.open}
      closeOnEsc
      onClose={props.close}
      center
      styles={newModalStyle({ width: "450px" })}
    >
      <form onSubmit={handleSubmit(ticketCancel)}>
        <div className="modal__title">
          {t("ModalCancelTicket.CancelTicket")}
        </div>

        <div>
          <LabelWithHR label={t("ModalCancelTicket.CancellationReason")} />
          <Controller
            name="cancellationReason"
            control={control}
            render={({ field }) => (
                <select
                  {...field}
                  className={Style.textStyleInputEditModal}
                  onChange={(e) => {
                    field.onChange(e); // Update form control
                    setCancelTicketReq({
                      ...cancelTicketReq,
                      cancellationReason: e.target.value,
                    });
                  }}
                  value={cancelTicketReq.cancellationReason}
                >
                <option value="" disabled>
                    {t("ModalCancelTicket.Select")} 
                </option>
                {cancellationReasons.map((reason) => (
                    <option key={reason.value} value={reason.value}>
                        {t(`CancellationReasons.${reason.value}`)} 
                    </option>
                ))}
                </select>
              )}
          />
        </div>
        <div>
          <LabelWithHR label={t("ModalCancelTicket.Justification")} />
          <Controller
            name="justification"
            control={control}
            render={() => (
              <input
                className={Style.textStyleInputEditModal}
                name="justification"
                type="text"
                onChange={(e) => {
                    setCancelTicketReq({
                    ...cancelTicketReq,
                    justification: e.target.value,
                  });
                }}
                defaultValue={cancelTicketReq.justification}
              />
            )}
          />
        </div>        

        <div className={Style.buttonContainer}>
          <Button
            style={{
              width: "25%",
              marginTop: 30,
            }}
            disabled={false}
            buttonStyle={{
              width: "100%",
            }}
            text={t("ModalCancelTicket.Cancel")}
          />
        </div>
      </form>
    </Modal>
  );
};
export default ModalCancelTicket;
